<template>
  <div class="create-result">
    <div class="page-content">
      <el-result icon="success" :title="$t('Order Created')">
        <template slot="extra">
          <router-link class="action-link" :to="{name:'Order', params: {id: orderId}}">
            <el-button type="success" size="medium">{{ $t('View Order')}}</el-button>
          </router-link>
          <router-link class="action-link" :to="{name:'CreateOrder'}">
            <el-button type="primary" size="medium">{{ $t('Back')}}</el-button>
          </router-link>
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';

export default {
  name: 'CompleteOrder',
  computed: {
    orderId(){
      return this.$route.params.id;
    },
  },
}
</script>

<style scoped lang="scss">
.create-result{
  .el-result{
    margin-top: 50px;
  }
  .action-link{
    margin-right: 30px;
    &:last-child{
      margin: 0;
    }
  }
}

</style>
