import queryString from 'query-string';

const Order = {
  async loadAllOrders(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/order?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading orders information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async searchOrder(baseApiUrl, searchFields, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        token: loginInfo['token'],
      };

      for(let i = 0; i < Object.keys(searchFields).length; i++){
        const key = Object.keys(searchFields)[i];
        const value = searchFields[key];
        queryList[key] = value;
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/order?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadOrder(baseApiUrl, orderId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/order/${orderId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createOrder(baseApiUrl, orderInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        token: loginInfo['token'],
        orderInfo: orderInfo,
      };

      const apiUrl = `${baseApiUrl}/pos/order`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create order information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateOrder(baseApiUrl, orderId, orderInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        orderInfo: orderInfo,
      };

      const apiUrl = `${baseApiUrl}/order/${orderId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update order information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteOrder(baseApiUrl, orderId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/order/${orderId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
}

export default Order;
